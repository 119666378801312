import * as State from '@shared/state';

import { Pickups } from '../pickups.utils';
import { Items } from '../items.utils';
import { Dates } from '../dates.utils';
import {
    ILoyaltyAppTransactionMenuFlow,
    ILoyaltyAppTransactionModel,
    ILoyaltyAppTransactionProduct,
    IMenuFlowDetailsModel,
    IMenuFlowPage,
    IOnlineOrderDetailedBusinessModel,
    IOpeningHoursModel,
    IOnlineOrderMenuFlowActivation,
} from '@shared/state';
import IOnlineMenuProductResponseModel = APICommon.IOnlineMenuProductResponseModel;

export class OnlineOrders {
    public static hasOrderTypePristineDisclaimerFields(orderType: APICommon.OrderTypeExtended, config: IConfig): boolean {
        if (!orderType) return false;

        const _ = orderType;
        const disclaimers = config.checkoutDisclaimers?.enabled === true;
        const totalDisclaimers = _.Disclaimers?.length || 0;
        const hasDisclaimers = totalDisclaimers > 0;
        const pristineOptionalDisclaimers = _.Disclaimers?.filter((obj) => obj.IsRequired !== true && typeof obj._Value !== 'boolean');

        return disclaimers && hasDisclaimers && pristineOptionalDisclaimers?.length > 0;
    }

    public static isOrderTypeDisclaimerValid(orderType: APICommon.OrderTypeExtended, config: IConfig): boolean {
        const _ = orderType;
        const disclaimers = config.checkoutDisclaimers?.enabled === true;

        const totalDisclaimers = _.Disclaimers?.length || 0;
        const hasDisclaimers = totalDisclaimers > 0;
        const requiredDisclaimers = _.Disclaimers?.filter((obj) => obj.IsRequired === true);
        const optionalDisclaimers = _.Disclaimers?.filter((obj) => obj.IsRequired !== true);
        const pristineDisclaimers = _.Disclaimers?.filter((obj) => obj._Value === null);
        const allDisclaimersAreUnset = hasDisclaimers ? pristineDisclaimers.length === totalDisclaimers : false;
        const hasPristineRequiredDisclaimers = requiredDisclaimers?.length ? requiredDisclaimers.find((obj) => obj._Value !== true) : false;
        const hasOnlyOptionalDisclaimersAndAllUnset = hasDisclaimers && optionalDisclaimers?.length === totalDisclaimers && allDisclaimersAreUnset ? true : false;

        if (!disclaimers || !hasDisclaimers || hasOnlyOptionalDisclaimersAndAllUnset) return true;

        if (allDisclaimersAreUnset || hasPristineRequiredDisclaimers) return false;

        return true;
    }

    public static detectOrderCollectionTypeGroup(order: IOnlineOrderDetailedBusinessModel, config: IConfig): OLO.Enums.COLLECTION_TYPE {
        if (!order) return null;
        const isDineIn =
            order.OrderTypeId === config.collectionTypes?.dineIn?.dineInBuzzer?.orderTypeId || order.OrderTypeId === config.collectionTypes?.dineIn?.dineInTable?.orderTypeId;

        if (isDineIn) return OLO.Enums.COLLECTION_TYPE.DINE_IN;

        const isDelivery = order.OrderTypeId === config.collectionTypes?.delivery?.orderTypeId;
        if (isDelivery) return OLO.Enums.COLLECTION_TYPE.DELIVERY;

        return OLO.Enums.COLLECTION_TYPE.PICKUP;
    }

    /**
     * Checks the group of provided order id
     *
     * @param {number} orderTypeId to check
     * @param {IConfig} config to get info about order type ids
     * @returns {OLO.Enums.COLLECTION_TYPE} collection type id
     */
    public static detectOrderTypeIdCollectionTypeGroup(orderTypeId: number, config: IConfig): OLO.Enums.COLLECTION_TYPE {
        if (!orderTypeId) return null;

        const isDineIn = orderTypeId === config.collectionTypes?.dineIn?.dineInBuzzer?.orderTypeId || orderTypeId === config.collectionTypes?.dineIn?.dineInTable?.orderTypeId;
        if (isDineIn) return OLO.Enums.COLLECTION_TYPE.DINE_IN;

        const isDelivery = orderTypeId === config.collectionTypes?.delivery?.orderTypeId;
        if (isDelivery) return OLO.Enums.COLLECTION_TYPE.DELIVERY;

        return OLO.Enums.COLLECTION_TYPE.PICKUP;
    }

    /**
     * Check if GROUP of two orderTypeIds is the same i.e. based on the config, check if both are DINE IN types or PICKUP TYPES
     *
     * @param {number} orderTypeId
     * @param {number} orderTypeId2
     * @returns {boolean}
     */
    public static orderTypesGroupMatch(orderTypeId: number, orderTypeId2: number, config: IConfig): boolean {
        return OnlineOrders.detectOrderTypeIdCollectionTypeGroup(orderTypeId, config) ===
        OnlineOrders.detectOrderTypeIdCollectionTypeGroup(orderTypeId2, config);
    }

    public static extendWithOrderTypeSurcharges(
        onlineOrder: IOnlineOrderDetailedBusinessModel,
        orderType: APICommon.OrderTypeExtended,
        extendItemsModel: boolean,
    ): IOnlineOrderDetailedBusinessModel {
        const baseModel: IOnlineOrderDetailedBusinessModel = {
            OrderTypeId: orderType?.Id || null,
            OrderTypeDisclaimers: orderType?.Disclaimers?.reduce(
                (acc, obj) => [
                    ...acc,
                    {
                        Id: null,
                        OrderId: null,
                        OrderDisclaimerDefinitionId: obj.Id,
                        IsAccepted: obj._Value,
                        DisplayIndex: obj.DisplayIndex,
                        CustomerFriendlyName: obj.CustomerFriendlyName,
                        CustomerFriendlyDescription: obj.CustomerFriendlyDescription,
                    },
                ],
                [],
            ),
            OrderTypeDetails:
                orderType?.Details?.reduce((acc, obj) => {
                    if (!obj._Value) return acc;

                    return [
                        ...acc,
                        {
                            DetailsDefinitionId: obj.Id,
                            ValueProvided: obj._Value || null,
                        },
                    ];
                }, []) || null,
            Items: [...(onlineOrder?.Items || [])],
            Medias: [...(onlineOrder?.Medias || [])],
        };

        if (extendItemsModel && orderType?.Surcharges?.length > 0) {
            baseModel.Surcharges = [
                ...orderType.Surcharges.map((obj) => {
                    baseModel.Items.push({
                        PLU: obj.PLU,
                        Value: obj.Value,
                        Quantity: 1,
                    });

                    return {
                        ...obj,
                        Id: null,
                        OrderSurchargeDefinitionId: obj.Id,
                    };
                }),
            ];
        }

        return baseModel;
    }

    public static onlineOrderModelFix(order: IOnlineOrderDetailedBusinessModel): IOnlineOrderDetailedBusinessModel {
        order.Items = order.Items.filter((item) => {
            const foundMenuFlowActivation = order.MenuFlowActivations.some((menuFlow) => menuFlow.MenuFlowItems.some((menuFlowItem) => menuFlowItem.OnlineOrderItemId === item.Id));

            const foundSurcharge = order.Surcharges.some((surcharge) => surcharge.PLU === item.PLU);

            return !foundMenuFlowActivation && !foundSurcharge;
        });

        return order;
    }

    public static isOrderActive(status: OLO.Enums.ONLINE_ORDER_STATUS): boolean {
        return status >= OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED && status < OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }

    public static isOrderFinalized(status: OLO.Enums.ONLINE_ORDER_STATUS): boolean {
        return status === OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }

    public static canOrder(
        location: APICommon.IOnlineOrderingLocationBusinessModel,
        futureOrders: boolean,
        asapPickupMins: number,
        openingHours: IOpeningHoursModel[],
        orderTimeoutBufferMins: number,
        startBufferMins: number,
    ): boolean {
        const pickupForToday: OLO.Ordering.IPickupForLocation = Pickups.calcInitialTimesObj(
            location.LocationNo,
            asapPickupMins,
            openingHours,
            orderTimeoutBufferMins,
            startBufferMins,
        );

        if (pickupForToday === null) {
            if (futureOrders && openingHours && openingHours.length > 0) {
                return true;
            }

            return false;
        }

        return pickupForToday.MinimumPickupTime >= pickupForToday.OpeningTime && pickupForToday.MaximumPickupTime <= pickupForToday.ClosingTime;
    }

    public static unduplicateOrderItems(order: IOnlineOrderDetailedBusinessModel): IOnlineOrderDetailedBusinessModel {
        if (!order) return null;
        const newOrder: IOnlineOrderDetailedBusinessModel = JSON.parse(JSON.stringify(order));
        const menuFlowProducts: number[] = [];

        newOrder.MenuFlowActivations.forEach((MenuFlow) => MenuFlow.MenuFlowItems.forEach((item) => menuFlowProducts.push(item.OnlineOrderItemId)));
        newOrder.Items = newOrder.Items.filter((item) => !menuFlowProducts.includes(item.Id));

        return newOrder;
    }

    public static generateDescriptionForMenuFlowActivationItem(menuFlowActivation: APICommon.IOnlineOrderMenuFlowActivation): string {
        return menuFlowActivation.MenuFlowItems.reduce((acc, item) => {
            if (item.IsHiddenFromUser) return acc;

            let qty = '';
            let prefix = '';
            if (acc) {
                prefix = ', ';
            }
            if (item.Quantity > 1) {
                qty = `${item.Quantity}x `;
            }

            let modifiersDescription: string = item.IngredientsChanges.IngredientsModified.reduce(
                (accM, itemM, indexM) => (accM += `${indexM && accM ? ', ' : ''}${itemM.ModifierName}`),
                '',
            );

            return (acc += `${prefix}${qty}${item.DisplayName}${modifiersDescription ? `(${modifiersDescription})` : ''}`);
        }, '').replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionForTransactionMenuFlowActivationItem(menuFlowActivation: ILoyaltyAppTransactionMenuFlow): string {
        return menuFlowActivation.MenuFlowProducts.reduce((acc, item) => {
            let qty = '';
            let prefix = '';
            if (acc) {
                prefix = ', ';
            }
            if (item.Quantity > 1) {
                qty = `${item.Quantity}x `;
            }

            let modifiersDescription: string = '';

            return (acc += `${prefix}${qty}${item.POSDisplay}${modifiersDescription ? `(${modifiersDescription})` : ''}`);
        }, '').replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionFromTransaction(transaction: ILoyaltyAppTransactionModel): string {
        if (!transaction) return null;

        let description = '';

        const descBuilder = (obj: ILoyaltyAppTransactionMenuFlow & ILoyaltyAppTransactionProduct) => {
            if (obj.ProductID === -99999) return;
            let prefix = '';
            let qty = '';
            if (!description) {
                prefix = '';
            } else {
                prefix = ', ';
            }
            if (obj.Quantity > 1) {
                qty = `${(obj as any).Quantity}x `;
            }

            description += `${prefix}${qty}${obj.CustomerFriendlyName || obj.MenuFlowDescription || obj.POSDisplay}`;
        };

        transaction.TransactionMenuFlows.forEach(descBuilder);
        transaction.TransactionProducts.forEach(descBuilder);

        return description.replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionFromOrder(order: IOnlineOrderDetailedBusinessModel): string {
        if (!order) return null;

        const fixedOrder = OnlineOrders.unduplicateOrderItems(order);
        let description = '';

        const descBuilder = (obj) => {
            let prefix = '';
            let qty = '';
            if (!description) {
                prefix = '';
            } else {
                prefix = ', ';
            }
            if (obj.Quantity > 1) {
                qty = `${obj.Quantity}x `;
            }

            description += `${prefix}${qty}${obj.DisplayName}`;
        };

        fixedOrder.MenuFlowActivations.forEach(descBuilder);
        fixedOrder.Items.forEach(descBuilder);

        return description.replace(/\s{1,},/g, ',');
    }

    private static extendIngredients(
        ingredients: APICommon.IProductLocationIngredientExtended[],
        ingredient: APICommon.IOnlineOrderItemIngredientModification,
        errors: OLO.Errors.IOrder2CartConvertErrors,
        menuFlowActivation: IOnlineOrderMenuFlowActivation,
        menuFlowProduct: APICommon.IOnlineOrderMenuFlowItem,
        baseMenuFlowProduct: APICommon.IMenuFlowProduct,
        order: IOnlineOrderDetailedBusinessModel,
    ): APICommon.IIngredientModifierExtended {
        // const freashIngredient: Models.IProductLocationIngredientExtended = ingredients.find(obj => obj.ProductID === menuFlowProduct.Id);
        const freashIngredient: APICommon.IProductLocationIngredientExtended = ingredients.find((obj) => obj.PLU === menuFlowProduct.PLU);

        if (!freashIngredient) {
            errors.menuFlows.push({
                errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                menuFlowId: menuFlowActivation.Id,
                error: `Unable to find coresponding ingredient ${ingredient.IngredientPLU} for product ${menuFlowProduct.Id}`,
            });
        }

        let modifier: APICommon.IIngredientModifierExtended;
        if (freashIngredient) {
            modifier = freashIngredient.Ingredients[0].Modifiers.find((obj) => obj.ModifierID === ingredient.ModifierID);

            if (!modifier) {
                errors.menuFlows.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    menuFlowId: menuFlowActivation.Id,
                    error: `Unable to find coresponding ingredient modifier ${ingredient.ID} ${ingredient.ModifierName} for product ${menuFlowProduct.Id}`,
                });
            }
        }

        return {
            ...ingredient,
            ...modifier,
            _IsOptional: modifier ? modifier._IsOptional : null,
            _ProductId: baseMenuFlowProduct.ProductId,
            _LocationNo: order.PickupLocation,
            _ProductPLU: baseMenuFlowProduct.Plu,
            ModifierName: modifier ? modifier.ModifierName : null,
        };
    }

    private static dealWithMenuFlow(
        menuFlowActivations: IOnlineOrderMenuFlowActivation[],
        errors: OLO.Errors.IOrder2CartConvertErrors,
        onlineMenu: APICommon.IOnlineMenuResponseModel,
        menuFlowsDetails: IMenuFlowDetailsModel[],
        order: IOnlineOrderDetailedBusinessModel,
        ingredients: APICommon.IProductLocationIngredientExtended[],
        fixMenuFlowActivationsDescriptions: boolean,
    ): State.ICartMenuFlow[] {
        return menuFlowActivations.map((menuFlowActivation) => {
            /* Get relevant online menu page and menu flow details for current menu flow activation */
            const baseOnlineMenuPage: APICommon.IOnlineMenuProductResponseModel = onlineMenu.Pages.reduce<APICommon.IOnlineMenuProductResponseModel>((acc, page) => {
                if (acc) return acc;

                const foundCorespondingMenuFlow = page.Products.find((obj) => obj.MenuFlowId === menuFlowActivation.MenuFlowId);
                if (foundCorespondingMenuFlow) {
                    return foundCorespondingMenuFlow;
                }

                return acc;
            }, null);

            const baseMenuFlowDetails: IMenuFlowDetailsModel = menuFlowsDetails.find((obj) => obj.MenuFlowId === menuFlowActivation.MenuFlowId && obj.IsActive === true);

            const isMenuFlowUpsell: boolean = menuFlowActivation.IsUpsell === true && baseOnlineMenuPage === null;
            if (!isMenuFlowUpsell) {
                if (!baseOnlineMenuPage) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: 'unable to find coresponding online menu page for menu flow activation item',
                    });

                    return null;
                }

                if (baseOnlineMenuPage.State !== 0) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `menuFlow (${baseOnlineMenuPage.MenuFlowId}) is currently unavailable - state property is not 0 (${baseOnlineMenuPage.State})`,
                    });

                    return null;
                }
            }

            if (!baseMenuFlowDetails) {
                errors.menuFlows.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    menuFlowId: menuFlowActivation.Id,
                    error: 'unable to find coresponding menu flow in online menu',
                });

                return null;
            }

            /* Restore pages container */
            const generatedPages: State.ICartMenuFlowPage[] = Items.createMenuFlowItemPagesFromMenuFlowDetailsModel<State.ICartMenuFlowPageProduct>(baseMenuFlowDetails);

            menuFlowActivation.MenuFlowItems.forEach((menuFlowProduct) => {
                const basePageForProduct: IMenuFlowPage = baseMenuFlowDetails.Pages.find((obj) => obj.PageNo === menuFlowProduct.MenuFlowPageId);
                /* Validate page params */
                if (!basePageForProduct) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `unable to find coresponding menu flow page (${menuFlowProduct.MenuFlowPageId}) in menu flow details`,
                    });

                    return;
                }

                const baseMenuFlowProduct: APICommon.IMenuFlowProduct = basePageForProduct.Products.find((product) => product.Plu === menuFlowProduct.PLU);
                /* Validate product params */
                if (!baseMenuFlowProduct) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `unable to find coresponding menu flow product (${menuFlowProduct.PLU}) in menu flow details`,
                    });

                    return;
                }

                if (baseMenuFlowProduct.State !== 0) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `product (${menuFlowProduct.PLU}) is currently unavailable - state property is not 0 (${baseMenuFlowProduct.State})`,
                    });

                    return;
                }

                /* Fetch page */
                const pageToFetch = generatedPages.find((obj) => obj.PageIdentifier === basePageForProduct.PageIdentifier);
                if (!pageToFetch) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `Unable to find page in generated pages - looking for ${basePageForProduct.PageIdentifier}
                        in menuFlow ${menuFlowProduct.OnlineOrderMenuFlowActivationId}
                        for product ${menuFlowProduct.DisplayName} with PLU ${menuFlowProduct.PLU}`,
                    });

                    return;
                }

                const productForPage = {
                    ...baseMenuFlowProduct,
                    ...(menuFlowProduct as any),
                    IngredientsChanges: {
                        ...(menuFlowProduct.IngredientsChanges as OLO.Ordering.IMenuFlowItemPageProductIngredientChanges),
                        IngredientsModified: menuFlowProduct.IngredientsChanges.IngredientsModified.map((ingredient) =>
                            this.extendIngredients(ingredients, ingredient, errors, menuFlowActivation, menuFlowProduct, baseMenuFlowProduct, order),
                        ),
                        IngredientsAdded: menuFlowProduct.IngredientsChanges.IngredientsAdded.map((ingredient) =>
                            this.extendIngredients(ingredients, ingredient, errors, menuFlowActivation, menuFlowProduct, baseMenuFlowProduct, order),
                        ),
                    },
                };

                pageToFetch.Products.push(productForPage);
            });

            const newMenuFlowActivation: State.ICartMenuFlowExtended = Items.createMenuFlowItemFromOnlineOrder(menuFlowActivation, baseMenuFlowDetails, {
                LocationNo: order.PickupLocation,
                _IsDisabled: !!errors.menuFlows.length,
                _Id: menuFlowActivation.Id,
                IsUpsell: menuFlowActivation.IsUpsell || false,
                Pages: generatedPages,
                DisplayDescription: fixMenuFlowActivationsDescriptions ? OnlineOrders.menuFlowActivationItemsDescription(menuFlowActivation).DisplayDescription : null,
            });

            return Items.updateMenuFlowItemPrices(baseMenuFlowDetails, newMenuFlowActivation);
        });
    }

    private static dealWithSimpleProduct(
        order: IOnlineOrderDetailedBusinessModel,
        onlineMenu: APICommon.IOnlineMenuResponseModel,
        errors: OLO.Errors.IOrder2CartConvertErrors,
    ): State.ICartSimpleItem[] {
        return order.Items.reduce((acc, item: APICommon.IOnlineOrderItemModel) => {
            let baseProduct: APICommon.IOnlineMenuProductResponseModel;
            const baseOnlineMenuPage: APICommon.IOnlineMenuPageResponseModel = onlineMenu.Pages.reduce<APICommon.IOnlineMenuPageResponseModel>((innerAcc, page) => {
                if (innerAcc) return innerAcc;
                baseProduct = page.Products.find((obj) => obj.Plu === item.PLU);
                if (baseProduct) {
                    innerAcc = page;
                }

                return innerAcc;
            }, null);

            if (!baseOnlineMenuPage) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    error: `Unable to find BASE PAGE for simple product ${item.DisplayName} with PLU ${item.PLU}`,
                });
            }

            if (!baseProduct) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    error: `Unable to find BASE PRODUCT for simple product ${item.DisplayName} with PLU ${item.PLU}`,
                });
            }

            if (baseProduct && baseProduct.State !== 0) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    error: `Product  ${item.DisplayName} with PLU ${item.PLU} is currently unavailable - state property is not 0 (${baseProduct.State})`,
                });
            }

            return [
                ...acc,
                Items.createSimpleItemFromOnlineOrderItemModel(item, {
                    _IsDisabled: !!errors.simpleItems.length,
                    LocationNo: order.PickupLocation,
                    DietaryTags: baseProduct ? baseProduct.DietaryTags : null,
                }),
            ];
        }, []);
    }

    private static checkOrderPickupTimeInOnlineMenuRange(
        order: IOnlineOrderDetailedBusinessModel,
        onlineMenu: APICommon.IOnlineMenuResponseModel,
        errors: OLO.Errors.IOrder2CartConvertErrors,
    ): void {
        const startTime = Dates.createHoursIntFromDate(onlineMenu.StartTime);
        const endTime = Dates.createHoursIntFromDate(onlineMenu.EndTime);
        const orderPickupTime = Dates.createHoursIntFromDate(order.PickUpDate);

        if (orderPickupTime < startTime || orderPickupTime > endTime) {
            errors.general.push({
                errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                error: 'Order pickupTime not in range of online menu StartTime and EndTime',
            });
        }
    }

    public static covertOrderToCart(
        onlineOrder: IOnlineOrderDetailedBusinessModel,
        onlineMenu: APICommon.IOnlineMenuResponseModel,
        menuFlowsDetails: IMenuFlowDetailsModel[],
        ingredients: APICommon.IProductLocationIngredientExtended[],
        pickupTime: OLO.Ordering.IPickupTime,
        fixMenuFlowActivationsDescriptions: boolean = true,
    ): OLO.Ordering.IOnlineOrder2CartConverter {
        const cart: State.ICart = {
            locationNo: onlineOrder.PickupLocation,
            onlineMenu,
            pickupTime: { ...pickupTime },
            itemsMenuFlow: null,
            itemsSimple: null,
            // pendingCartClear: false,
        };

        const errors: OLO.Errors.IOrder2CartConvertErrors = {
            general: [],
            menuFlows: [],
            simpleItems: [],
        };
        const order: IOnlineOrderDetailedBusinessModel = OnlineOrders.unduplicateOrderItems(onlineOrder);

        /* #1 deal with menuflows */
        const itemsMenuFlow: State.ICartMenuFlow[] = this.dealWithMenuFlow(
            order.MenuFlowActivations,
            errors,
            onlineMenu,
            menuFlowsDetails,
            order,
            ingredients,
            fixMenuFlowActivationsDescriptions,
        );

        /* #2 deal with products */
        const itemsSimple: State.ICartSimpleItem[] = this.dealWithSimpleProduct(order, onlineMenu, errors);

        /* #3 check if order pickupTime is in current online menu range */
        this.checkOrderPickupTimeInOnlineMenuRange(order, onlineMenu, errors);

        cart.itemsMenuFlow = itemsMenuFlow.filter((item) => item !== null && item !== undefined);
        cart.itemsSimple = itemsSimple;

        return {
            cart,
            errors,
        };
    }

    public static convertCart(
        saleName: string,
        cart: State.ICart,
        PickupObj: OLO.Ordering.IPickupTime,
        extraParams: APICommon.ICartToOrderConvertExtraParams = {},
    ): IOnlineOrderDetailedBusinessModel {
        if (!PickupObj) return null;
        // Doing https://lh3.googleusercontent.com/V8ehXTGa-NIpxHJjZKxOtajAUlhIZE483BpEPH49nJRCxJfX4ifC8d8WU9bXr0n9uQqwLtTdivfuog=w270-h130-rw-no stuff...
        const locationNo: number = cart.locationNo;
        /* Brejnfak, normalize hours */
        const createdDate = Dates.getLocalISOFormatDate(new Date(), true);
        const pickupTime = PickupObj.DateLocalISO + 'Z'; /* Z is required in our sick api */

        return {
            SaleName: `${saleName} ${createdDate.replace(/:\d{2}\.\d{3}Z?/i, '')}`,
            MemberId: extraParams.MemberId || null,
            PartialMember: extraParams.PartialMember || null,
            OnlineOrderType: extraParams.OnlineOrderType || 0,
            OrderTypeId: extraParams.OrderTypeId || null,
            PickupLocation: locationNo,
            PickUpDate: pickupTime,
            OrderedDate: createdDate,
            IsDelivery: false,
            Status: OLO.Enums.ONLINE_ORDER_STATUS.CREATED,
            SendToKMS: true,
            OnlineDiscounts: [],
            Medias: [
                /* Boldman says 'NO MEDIA UGHhhh' */
            ],
            Items: cart.itemsSimple.map((product) => ({
                ...product,
                PLU: product.Plu,
                Value: product.UnitPrice * product.Quantity,
                Modifiers: [],
                IngredientsChanges: {
                    IngredientsModified: [],
                    IngredientsRemoved: [],
                    IngredientsAdded: [],
                    IngredientsSwapped: [],
                },
            })),

            MenuFlowActivations: [
                /* MenuFlowActivations start */
                ...cart.itemsMenuFlow.map((menuFlow) => ({
                    Id: null,
                    IsUpsell: menuFlow.IsUpsell || false,
                    MenuFlowId: menuFlow.MenuFlowId,
                    Quantity: menuFlow.Quantity,
                    UnitPrice: menuFlow.UnitPrice,
                    SpecialInstructions: menuFlow.SpecialInstructions || null,
                    Value: menuFlow.UnitPrice * menuFlow.Quantity /* ( Value including UnitPrice and total menu flows products value ) * Quantity */,
                    MenuFlowItems: [
                        ...menuFlow.Pages.reduce(
                            (acc, page) =>
                                acc.concat(
                                    page.Products.map((product) => ({
                                        Id: null,
                                        OnlineOrderItemId: null,
                                        Type: null,
                                        MenuFlowPageId: product.PageNo,
                                        PLU: product.Plu,
                                        Quantity: product.Quantity,

                                        /* These values should be CALCULATED using condition for Page */
                                        UnitPrice: product.UnitPrice,
                                        Value: product.UnitPrice * product.Quantity,

                                        IngredientsChanges: {
                                            IngredientsModified: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<APICommon.IOnlineOrderItemIngredientModification>(product, 'IngredientsModified'),
                                            IngredientsRemoved: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<APICommon.IOnlineOrderItemIngredientRemoval>(product, 'IngredientsRemoved'),
                                            IngredientsAdded: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<APICommon.IOnlineOrderItemIngredientAddition>(product, 'IngredientsAdded'),
                                            IngredientsSwapped: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<APICommon.IOnlineOrderItemIngredientSwap>(product, 'IngredientsSwapped'),
                                        },
                                    })),
                                ),
                            [],
                        ),
                    ],
                })),
                /* MenuFlowActivations end */
            ],
        };
    }

    public static modifierPropsTrimmer<T>(product: State.IWizzardMenuFlowItem, propName: string): T[] {
        if (!product.IngredientsChanges || !product.IngredientsChanges[propName]) return [];

        const trimmedArray: T[] = [];

        product.IngredientsChanges[propName].forEach((modifier) => {
            const newModifierObj: T = {} as T;

            Object.keys(modifier).forEach((key) => {
                if (key.charAt(0) !== '_') {
                    newModifierObj[key] = modifier[key];
                }
            });

            trimmedArray.push(newModifierObj);
        });

        return trimmedArray;
    }

    public static mapOnlineOrderProducts(
        order: IOnlineOrderDetailedBusinessModel,
        fixMenuFlowDescriptions: boolean = false,
        onlineProducts: IOnlineMenuProductResponseModel[] = null,
    ): OLO.Ordering.IOnlineOrderMappedProducts {
        const distinctMenuFlowProducts: number[] = [];

        const obj: OLO.Ordering.IOnlineOrderMappedProducts = {
            itemsSimple: [],
            itemsMenuFlow: [],
        };

        order.MenuFlowActivations.forEach((menuFlowActivation) => {
            menuFlowActivation.MenuFlowItems.forEach((item) => distinctMenuFlowProducts.push(item.OnlineOrderItemId));
        });

        obj.itemsMenuFlow = [...order.MenuFlowActivations];

        if (onlineProducts) {
            const filteredItems = [...order.Items].filter((item) => !distinctMenuFlowProducts.includes(item.Id));

            filteredItems.forEach((item) => {
                const product = onlineProducts.filter((prod) => prod.Plu === item.PLU)[0];

                obj.itemsSimple.push({
                    ...item,
                    UnitPrice: product.Price,
                });
            });
        } else {
            obj.itemsSimple = [...order.Items].filter((item) => !distinctMenuFlowProducts.includes(item.Id));
        }

        if (fixMenuFlowDescriptions) {
            obj.itemsMenuFlow = obj.itemsMenuFlow.map(OnlineOrders.menuFlowActivationItemsDescription);
        }

        return obj;
    }

    public static menuFlowActivationItemsDescription(menuFlowActivation: APICommon.IOnlineOrderMenuFlowActivation): APICommon.IOnlineOrderMenuFlowActivation {
        return {
            ...menuFlowActivation,
            DisplayDescription: OnlineOrders.generateDescriptionForMenuFlowActivationItem(menuFlowActivation),
        };
    }
}
