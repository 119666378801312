import * as State from '../../state';
import { Arrays } from './arrays.utils';
import { Spread } from './spread.utils';
import { IMenuFlowDetailsModel, IMenuFlowPage } from '../../state';

export class Pricing {

    public static isPageSpecialPricingLimitReached(pageDetails: IMenuFlowPage, productsList: APICommon.IMenuFlowProduct[]): boolean {
        const qty: number = productsList.reduce((acc, product) => acc += product.Quantity, 0);

        return qty >= pageDetails.SpecialPriceQuantity;
    }

    public static sortDetails(pageDetails: IMenuFlowPage): { property: string; direction: string; } {
        /* Sort flatten items by condition from page */
        let property: string = 'default';
        let direction: string = null;

        switch (pageDetails.SpecialPriceSelection) {
            case OLO.Enums.PRICE_MODE.MOST_EXPENSIVE:
                property = 'OverridedPrice';
                direction = 'desc';
                break;

            case OLO.Enums.PRICE_MODE.MOST_EXPENSIVE_ORIGINAL_PRICE:
                property = 'OriginalPrice';
                direction = 'desc';
                break;

            case OLO.Enums.PRICE_MODE.LEAST_EXPENSIVE:
                property = 'OverridedPrice';
                direction = 'asc';
                break;

            case OLO.Enums.PRICE_MODE.LEAST_EXPENSIVE_ORIGINAL_PRICE:
                property = 'OriginalPrice';
                direction = 'asc';
                break;

            case OLO.Enums.PRICE_MODE.OFF:
            default:
                break;
        }

        return {
            property,
            direction,
        };
    }

    public static calculatePricesFromMenuFlow(
        menuFlowDetails: IMenuFlowDetailsModel,
        contents: State.IWizzardMenuFlow | State.ICartMenuFlow): OLO.Ordering.IPricingMenuFlow {
        let totalValueMenuFlowProducts: number = 0; /* Overall price for all products in menuFlow... ingredients modifiers not included */
        let HasErrors: boolean = false;
        const pagesArr: OLO.Ordering.IPricingMenuFlowPage[] = [];
        contents.Pages.forEach(Page => {
            /* Wizzard/Cart menuFlow Page start */
            const pageDetails: IMenuFlowPage = menuFlowDetails.Pages.find(page => page.PageIdentifier === Page.PageIdentifier);
            /* Get details how to calculate price for specific page */
            const sortDetails = Pricing.sortDetails(pageDetails);
            /* Sort flatten items by condition from page */
            const flattenProducsByQuantity: State.IWizzardMenuFlowItem[] = Spread.menuFlowItemPageProductsByQuantity(Page.Products)
                .sort(Arrays.sortByProp(sortDetails.property, sortDetails.direction));
            // flattenProducsByQuantity = flattenProducsByQuantity;
            /* Calculate each product UnitPrice - same products may have different prices depending on pageDetails settings */
            let processedProducts: number = 0;
            const ungrouppedProductsPricingArr: OLO.Ordering.IPricingMenuFlowItem[] = [];

            flattenProducsByQuantity.forEach(product => {
                let productUnitPrice: number = 0;

                if (!product.ExcludeFromSpecialPricing) {
                    const MFOverridedPrice = menuFlowDetails.OverridePrice;

                    switch (true) {
                        case sortDetails.property === 'default':
                            if (MFOverridedPrice !== null) {
                                productUnitPrice = product.OverridedPrice === null ? 0 : product.OverridedPrice;
                            } else {
                                productUnitPrice = product.OverridedPrice !== null ? product.OverridedPrice : product.OriginalPrice;
                            }
                            break;
                        case processedProducts < pageDetails.SpecialPriceQuantity:
                            productUnitPrice = pageDetails.SpecialPrice;
                            break;

                        case sortDetails.property === 'OriginalPrice' || sortDetails.property === 'OverridedPrice':
                            productUnitPrice = product[sortDetails.property];
                            if (productUnitPrice === null) {
                                HasErrors = true;
                                console.warn(`MenuFlow SETUP issue!!! Setting price by property ${sortDetails.property} but product price is null:`, product);
                                console.warn('Set -1 to product page SpecialPriceSelection or set proper product price');
                            }
                            break;

                        default:
                            HasErrors = true;
                            console.error(`Unhandeled price calulcation scenario, property: ${sortDetails.property}, product:`, product);
                            throw new Error('Unhandeled price calulcation scenario');
                    }

                    ++processedProducts;
                } else {
                    /* WHAT TO DO WHEN PRODUCT IS EXCLUDED FROM SPECIAL PRICING?? */
                    productUnitPrice = product.OverridedPrice !== null ? product.OverridedPrice : product.OriginalPrice;
                }

                let displayPrice = productUnitPrice;
                if (product.IngredientsChanges?.IngredientsModified && product.IngredientsChanges?.IngredientsModified[0]?.ExtraPrice) {
                    displayPrice += product.IngredientsChanges.IngredientsModified[0].ExtraPrice;
                }
                /* Update totalValueMenuFlowProducts to add up later for total menuflow value */
                totalValueMenuFlowProducts += productUnitPrice;

                /* Start building products array for page */
                ungrouppedProductsPricingArr.push({
                    ProductName: product.ProductName || product.ProductDescription,
                    PageProductIdentifier: product.PageProductIdentifier,
                    PageIdentifier: pageDetails.PageIdentifier,
                    MenuFlowId: product.MenuFlowID,
                    ProductId: product.ProductId,
                    Quantity: 1,
                    UnitPrice: productUnitPrice,
                    TotalValue: productUnitPrice,
                    DisplayPrice: displayPrice,
                });

            });

            /* Group products array with summed values */
            const productsGroupped: OLO.Ordering.IPricingMenuFlowItem[] = [];
            ungrouppedProductsPricingArr.forEach(obj => {
                const existingItemIndex: number = productsGroupped
                    .findIndex(existingItem =>
                        existingItem.ProductId === obj.ProductId &&
                        existingItem.PageProductIdentifier === obj.PageProductIdentifier
                    );
                if (existingItemIndex === -1) {
                    /* Add new one if not found */
                    return productsGroupped.push({
                        ...obj,
                    });
                }

                const existing: OLO.Ordering.IPricingMenuFlowItem = productsGroupped[existingItemIndex];
                const newQuantity: number = existing.Quantity + 1;
                const newTotal: number = existing.TotalValue + obj.UnitPrice;
                const newUnitPrice: number = newTotal / newQuantity;

                productsGroupped[existingItemIndex].Quantity = newQuantity;
                productsGroupped[existingItemIndex].TotalValue = newTotal;
                productsGroupped[existingItemIndex].UnitPrice = newUnitPrice;

            });

            /* Group products array end */

            /* Construct nice data array */
            pagesArr.push({
                PageIdentifier: Page.PageIdentifier,
                Products: productsGroupped
            });
            /* Wizzard/Cart menuFlow Page end */
        });

        const result: OLO.Ordering.IPricingMenuFlow = {
            HasErrors,
            MenuFlowId: menuFlowDetails.MenuFlowId,
            MenuFlowName: menuFlowDetails.MenuFlowName,
            OverridedPrice: menuFlowDetails.OverridePrice,
            LocationNo: contents.LocationNo,
            UnitPrice: menuFlowDetails.OverridePrice, // + totalValueMenuFlowProducts, /* CHECK THAT */
            UnitTotalValue: (menuFlowDetails.OverridePrice || 0) + totalValueMenuFlowProducts,
            Quantity: contents.Quantity,
            Pages: pagesArr,
        };

        return result;

    }

    public static calculateExtraPriceForIngredients(menuFlow: State.ICartMenuFlow & State.ICartSimpleItem): number {
        return menuFlow.Pages.reduce((prev, curr) => prev + curr.Products
            .reduce((prevProd, currProd) =>
                currProd.IngredientsChanges?.IngredientsModified[0]?.ExtraPrice ?
                    prevProd + currProd.IngredientsChanges.IngredientsModified[0].ExtraPrice :
                    prevProd,
            0), 0);
    }
}
